.correct {
  color: #79aea3;
}

.default {
  color: #f7f7ff;
}

.incorrect {
  color: #d62828;
}

.missed {
  color: darkred;
}

.extra {
  color: #8b0000;
}

.key-manager {
  border: transparent;
}

.word {
  margin: 0.25em;
  border-bottom: 2px solid transparent;
  line-height: 1em;
  box-sizing: border-box;
}

.blink {
  -webkit-animation: blink 2s infinite both;
  animation: blink 2s infinite both;
}

/* .word-manager-wrapper {
  animation: fadeIn 4s;
}

.word-manager {
  animation: fadeIn 4s;
} */

.typing-test {
  animation: fadeIn 4s;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
